<template>
  <v-main>
    <v-container>
      <div class="content content-text">
        <p>
          Будь ласка, уважно прочитайте Договір публічної оферти, правила і
          умови використання сайту та правила конфіденційності, перед тим, як
          користуватися послугами сайту
          <a href="https://p-trans.front-dev.bmax.com.ua/">p-trans.pl.ua</a>
        </p>
        <p>
          Якщо Ви не згодні з Договором публічної оферти, правилами та умовами,
          будь ласка, не використовуйте сайт
          <a href="https://p-trans.front-dev.bmax.com.ua/">p-trans.pl.ua</a>
        </p>
        <p>
          Зауважте, що Договір публічної оферти, правила і умови можуть
          змінюватися без попередження. Зміни в Договорі публічної оферти,
          правилах і умовах набирають чинності з моменту їх публікації на сайті
          <a href="https://p-trans.front-dev.bmax.com.ua/">p-trans.pl.ua</a>
        </p>
        <p>
          Здійснення вами оплати є свідченням вашого безумовного акцепту
          договору публічної оферти та погодження з правилами й умовами,
          визначеними власником сайту
          <a href="https://p-trans.front-dev.bmax.com.ua/">p-trans.pl.ua</a>
        </p>
        <p>Текст від 10 березня 2020 року.</p>
        <p>&nbsp;</p>
        <p><strong>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ</strong></p>
        <p>
          Товариство з обмеженою відповідальністю «П-ТРАНС» (далі P-Trans),
          ЄДРПОУ 38026177, адреса: 78650, Україна, Cоколівка, вулиця Зелена 26,
          керуючись ст. 633. Цивільного кодексу України, пропонує необмеженому
          колу осіб (далі Користувач) укласти Договір публічної оферти (далі
          Договір), з метою пошуку та/або придбання квитків за допомогою сайту
          <a href="https://p-trans.front-dev.bmax.com.ua/">p-trans.pl.ua</a>,
          та/або додаткових сервісів P-Trans.
        </p>
        <p>
          Користувач може діяти в межах Договору від свого імені та на свою
          користь та/або від імені третьої сторони та на її користь, у такому
          випадку Користувач діє виключно в межах повноважень наданих третьою
          стороною.
        </p>
        <p>
          P-Trans є інтегрованим дистриб’ютором послуг перевезення, що
          поставляються перевізниками, автостанціями, консолідаторами ресурсів
          перевізника та третіми особами, далі званими «Постачальник».
        </p>
        <p>
          Квиток (у тому числі і електронний квиток) – проїзний документ
          встановленої форми, який дає право пасажиру на одержання транспортних
          послуг автобусного перевезення, створений в автоматизованій системі
          P-Trans, надсилається на електронну адресу вказану при замовленні або
          іншим чином видається Користувачу.
        </p>
        <p>
          Номер квитка є номером договору публічної оферти і номером договору
          пасажирського перевезення.
        </p>
        <p>
          Користувач і P-Trans обізнані із загальними вимогами укладання
          Договорів, додержання яких є необхідним для чинності правочину,
          володіючи повним обсягом цивільної дієздатності та цивільної
          правоздатності, мають відповідні повноваження на укладання такого
          правочину, повністю усвідомлюючи значення своїх дій та згідно з
          вільним волевиявленням, яке відповідає внутрішній волі учасників цього
          правочину, маючи на меті реальне настання правових наслідків,
          розуміючи правову природу цього правочину, а також свої права та
          обов’язки за Договором, у відповідності до чинного законодавства
          уклали цей Договір про нижче викладене:
        </p>
        <p>&nbsp;</p>
        <p><strong>ПРЕДМЕТ ДОГОВОРУ</strong></p>
        <p>
          За Договором, P-Trans надає послуги з пошуку відповідного
          Постачальника, у відповідності до дати, часу та напрямку обраного
          Користувачем, формування стиковок різних Постачальників, укладення
          договору перевезення (шляхом продажу/придбання Квитка), оформлення
          квитків різних Постачальників одним замовленням, попереднього продажу
          квитків, бронювання місць, замовлення квитка по телефону,
          переоформлення квитка, пошук оптимального розкладу за запитом
          Користувача, сервісне обслуговування користувача, у тому числі і
          цілодобову підтримку Користувача Центром сервісного обслуговування,
          додаткові послуги за запитами Користувача, а також попередньої оплати
          Квитка коштами отриманими від Користувача (далі Послуги).
        </p>
        <p>
          За Послуги, що надаються, P-Trans отримує винагороду, що складається
          із суми коштів, що входять до вартості Квитка та визначені, як
          Агентська винагорода (оплачується Постачальником) та Сервісний збір
          (оплачується Користувачем).
        </p>
        <p>
          P-Trans, діючи як агент, від імені, за рахунок в інтересах
          Постачальника, за допомогою сайту, продає автобусне пасажирське
          перевезення (далі Квиток), спеціальні пропозиції, знижки та надає
          інформацію Постачальника.
        </p>
        <p>
          Кожне придбання Квитка окремо взятого Постачальника, підпорядковується
          правилам та умовам саме цього Постачальника і є невід’ємною частиною
          Договору.
        </p>
        <p>
          У випадку придбання декількох Квитків одним замовленням, правила і
          умови одного чи іншого Постачальника можуть відрізнятись.
        </p>
        <p>
          Недотримання правил і умов Постачальника та/або P-Trans може призвести
          до скасування замовлень і відмову у доступі до Сайту та/або придбаних
          послуг, у тому і числі і можливістю скористатись Квитком, без
          повернення витрачених на його придання коштів.
        </p>
        <p>
          Придбання Квитка Користувачем на пільгових умовах (безкоштовне
          перевезення і т.п.) відповідно до законодавства України, здійснюється
          виключно в касі/офісі відповідного Постачальника, або за дозволом
          відповідного Постачальника.
        </p>
        <p>&nbsp;</p>
        <p><strong>ПРАВА І ОБОВ’ЯЗКИ СТОРІН</strong></p>
        <p><strong>Користувач має право:</strong></p>
        <ul>
          <li>Отримати послуги за Договором;</li>
          <li>
            Скористатись Квитком, у відповідності до обраної дати, часу, місця
            відправлення та обраного Постачальника;
          </li>
          <li>
            Повернути Квиток, на умовах визначених Постачальником та/або P-Trans
          </li>
          <li>
            Вимагати компенсації завданих збитків, що виникає з умов Договору,
            шляхом направлення відповідного звернення на адресу P-Trans.
          </li>
          <li>
            У випадку завдання моральної шкоди Користувачу, діями або
            бездіяльністю P-Trans, сума відшкодування, при її доведенні та
            обґрунтуванні не може перевищувати суму більшу чим вартість Квитка.
          </li>
          <li>
            Деякі Постачальники, зобов’язують здійснити обмін та/або повернення
            квитка виключно у касі автостанції без можливості повернення коштів,
            через сайт або в офісі партнера P-Trans, де проводилась оплата.
            Умови такого обміну та/або повернення наведені у Квитку.
          </li>
        </ul>
        <p><strong>Користувач зобов’язаний:</strong></p>
        <ul>
          <li>
            Здійснити попередню оплату Квитка, у 100 % вартості вказаній на
            сайті;
          </li>
          <li>
            Перевірити дату, час, місто/місце відправлення, місто/місце прибуття
            одразу після придбання Квитка;
          </li>
          <li>Завчасно прибути до місця відправлення;</li>
          <li>
            Повідомити P-Trans про неможливість використання Квитка з тієї чи
            іншої причини;
          </li>
          <li>Ознайомитись з умовами повернення Квитка;</li>
          <li>
            Зв’язатись з P-Trans до моменту оплати Квитка, у випадку
            відсутності, умов повернення придбаного Квитка обраного
            Постачальника на Сайті.
          </li>
          <li>
            Купуючи Квиток на двох або більше Постачальників або до пункту
            здійснення пересадки, за допомогою Сайту, Користувач зобов’язаний
            самостійно визначити достатню кількість часу для здійснення
            пересадки, у тому числі якщо така посадка запропонована на Сайті.
            Рекомендована кількість годин очікування в пункті пересадки
            становить, для внутрішньо державних рейсів 4 години, для
            міждержавних 8 годин від моменту прибуття за розкладом.
          </li>
          <li>
            Користувач зобов’язаний ознайомитись з правилами і умовами обраного
            Постачальника до придбання квитка обраного Постачальника, тим самим
            надає згоду виконувати і діяти відповідно до правил та умов
            Постачальника. Деякі Постачальники надають можливість визначити
            умови повернення певного квитка тільки після його придбання.
            Виходячи з такої специфіки Користувач погоджується, з тим що
            утримання при поверненні такого квитка, може дорівнювати 100%
            вартості квитка, навіть при умові оформлення повернення квитка через
            15 хвилин після його оплати.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>P-Trans<strong> має право:</strong></p>
        <ul>
          <li>
            Діяти від імені Постачальника, в межах повноважень визначених
            договірними відносинами;
          </li>
          <li>
            Укласти договір перевезення між Користувачем та Постачальником, на
            умовах та в спосіб визначений Постачальником;
          </li>
          <li>
            Встановити та отримати додаткову винагороду з Користувача, за
            додатково надані сервіси та/або послуги.
          </li>
          <li>
            P-Trans має право записувати телефонні розмови з Користувачем та/або
            Пасажиром, у випадку звернення до сервісного центру та/або у випадку
            обслуговування Користувача/Пасажира та/або необхідності повідомити
            Користувача/Пасажира та/або отримати додаткові відомості у
            відношенню будь якого Квитка, здійсненого за допомогою сервісу
            P-Trans та/або Сайту.
          </li>
        </ul>
        <p>P-Trans<strong> зобов’язаний:</strong></p>
        <ul>
          <li>
            Вчасно повідомити Постачальника про придбаний Квиток, і додаткові
            послуги оплачені Користувачем;
          </li>
          <li>
            Надіслати Користувачу Квиток відповідно до форми встановленої і
            прийнятої Постачальником;
          </li>
          <li>
            Переказати кошти отримані від Користувача, в якості оплати Квитка,
            на розрахунковий рахунок Постачальника;
          </li>
          <li>
            Повідомити Користувача, про зміни в умовах надання послуг (за умови
            повідомлення про такі зміни Постачальником);
          </li>
          <li>
            Повідомити Користувача, про зміну або скасування рейсу (у випадку
            надходження такої інформації від Постачальника);
          </li>
          <li>
            Прийняти звернення від Користувача, розглянути його самостійно або
            направити для розгляду відповідному Постачальнику;
          </li>
          <li>
            Своєчасно повідомити Користувача про розгляд звернення, а у випадку
            надсилання звернення Постачальнику про результати його розгляду (у
            випадку надходження такої відповіді на адресу P-Trans).
          </li>
          <li>
            P-Trans, надає відповідь на звернення, способом аналогічним до його
            надходження. Надсилання відповіді третій стороні (у тому числі і за
            проханням Користувача) здійснюватися не буде.
          </li>
        </ul>
        <p><strong>ВАРТІСТЬ ПОСЛУГ І ПОРЯДОК РОЗРАХУНКІВ</strong></p>
        <p>
          Замовник сплачує вартість ППД/ЕПД та Збір Виконавця шляхом їх 100%
          оплати за допомогою засобів платежу, доступ до яких забезпечено на
          Веб-сайті, та у відповідності до правил платіжних систем.
        </p>
        <p>
          Вартість ППД/ЕПД та Збір Виконавця вважаються сплаченими, якщо
          платіжною системою надана інформація щодо списання коштів з рахунку
          Замовника на користь Виконавця.
        </p>
        <p>
          Збір Виконавця за надані послуги поверненню не підлягає, за винятком
          випадків, що сталися з вини Виконавця чи Перевізників.Повернення
          грошових коштів за невикористані ППД/ЕПД, що були оформлені за
          допомогою Веб-сайту, відбувається згідно з умовами повернення
          квитків.Посадочний документ у міжнародному сполученні не підлягає
          переоформленню/ перетворенню , обміну в касі.
        </p>
        <p>&nbsp;</p>
        <p><strong>ВЗАЄМОРОЗРАХУНКИ</strong></p>
        <p>
          Кошти отримані від Користувача, в рахунок придбання Квитка належать
          Постачальнику (за виключенням Сервісного збору P-Trans (у випадку,
          якщо такий входив до вартості Квитка). P-Trans проводить розрахунок за
          придбаний Користувачем Квиток на у мовах і в строки визначені
          Постачальником.
        </p>
        <p>
          Користувач одноосібно відповідальний за всі комісійні витрати та інші
          визначені банком витрати, які можуть бути додані до ціни Квитка або
          додаткових послуг, емітентом кредитної або дебетної картки, крім
          випадків, в яких витрати приписані до відповідальності P-Trans.
        </p>
        <p>
          Банківські послуги, пов’язані з переказом коштів в межах Договору,
          оплачуються Стороною, яка їх здійснює.
        </p>
        <p>&nbsp;</p>
        <p><strong>УМОВИ ПОВЕРНЕННЯ КВИТКА</strong></p>
        <p><strong>Примусове повернення коштів за Квиток:</strong></p>
        <ul>
          <li>
            У випадку повідомлення Постачальником про скасування рейсу, з тієї
            чи іншої причини, P-Trans здійснює повернення Коштів отриманих в
            якості оплати Квитка, шляхом яким вони надійшли у 100% розмірі до
            вартості Квитка, або за наявності, пропонує придбати інший Квиток на
            рейс того самого або іншого Постачальника.
          </li>
          <li>
            Вартість Квитка на рейс іншого постачальника може відрізнятись.
          </li>
        </ul>
        <p><strong>Добровільне повернення:</strong></p>
        <ul>
          <li>
            У разі оформлення повернення Квитка, за власної ініціативи
            Користувача, P-Trans повертає кошти користувачу керуючись даними
            автоматизованих розрахунків повернення грошових коштів наданих
            Постачальником;
          </li>
          <li>
            Умови повернення Квитка кожного Постачальника відрізняється у
            відповідності до внутрішніх правил;
          </li>
          <li>
            Агентська винагорода та Сервісний збір P-Trans, у випадку
            добровільного повернення Квитка, поверненню не підлягає;
          </li>
          <li>
            Постанова Кабінету Міністрів України № 176 від 18 лютого 1997 р.
            «Про затвердження правил надання послуг пасажирського автомобільного
            транспорту», регулює питання повернення Квитка виключно у касу
            Автостанції, а також не охоплює поняття Постачальник, у
            відповідності до умов Договору.
          </li>
        </ul>
        <p><strong>ІДЕНТИФІКАЦІЯ КОРИСТУВАЧА САЙТУ p-trans.pl.ua</strong></p>
        <p>
          У якості ідентифікатора Користувача використовується e-mail адреса
          та/або номер телефону, що вказується при придбанні Квитка.
        </p>
        <p>
          Зверненням з номера телефона/e-mail адреси залишеної при придбанні
          Квитка, дозволяє P-Trans ідентифікувати Користувача, як належну
          Сторону Договору з відповідними правами та обов’язками, і дозволяє
          здійснювати дії по відношенню, як до Договору загалом, так і до
          конкретно придбаного Квитка.
        </p>
        <p>
          Звернення Користувача з іншого номера телефона та/або e-mail адреси
          наданого при придбанні Квитка, дає право P-Trans вимагати від
          Користувача додатково ідентифікувати себе, як належну сторону
          Договору.
        </p>
        <p>
          У випадку неможливості ідентифікувати Користувача, як сторону Договору
          P-Trans залишає за собою право відмовити у розгляді звернення.
        </p>
        <p>
          Відповідальність за збереження та/або доступ до номера телефона та/або
          e-mail адреси покладається на Користувача.
        </p>
        <p>&nbsp;</p>
        <p><strong>УМОВИ НАДАННЯ ІНФОРМАЦІЇ</strong></p>
        <p>&nbsp;</p>
        <p>
          Для здійснення оформлення та придбання Квитка на Сайті необхідно
          надати певну інформацію, а саме: Ім’я і Прізвище пасажира (номер
          документу за яким його можна ідентифікувати (встановлюється опційно
          Постачальником)), діючу е-mail адресу та номер телефону.
        </p>
        <p>
          Також при покупці необхідно вказати платіжну інформацію, пов’язану з
          банківськими картками – тобто їх номери і терміни дії. Вводити дану
          інформацію необхідно при кожній покупці на захищеному сервісі обраної
          платіжної системи.
        </p>
        <p>&nbsp;</p>
        <p><strong>ГАРАНТІЇ СТОРІН</strong></p>
        <p>
          Здійснюючи покупку через Сайт Користувач підтверджує, свою
          дієздатність та дає згоду на обробку своїх персональних даних.
        </p>
        <p>
          Користувач погоджуєтеся сплатити ціну Квитка і відповідні податки для
          всіх зроблених покупок, оплачених кредитними або дебітними картками
          або іншим дозволеним методом оплати.
        </p>
        <p>
          Користувач погоджується сплатити всі визначені P-Trans кошти у разі
          якщо платіж не здійснено, повернуто або відхиллено банком Корисувача.
        </p>
        <p>
          P-Trans, здійснюючи продаж Квитка за допомогою Сайту, підтверджує
          наявне у нього право на продаж даного Квитка, у відповідності до умов
          і обов’язків, визначених Постачальником.
        </p>
        <p>&nbsp;</p>
        <p><strong>КОНФІДЕНЦІЙНА ІНФОРМАЦІЯ</strong></p>
        <p>
          Користувач підтверджує згоду з тим, що несе особисту відповідальність
          за надану для збереження конфіденційної інформації.
        </p>
        <p>
          P-Trans не несе відповідальності за будь-які збитки, завдані внаслідок
          несанкціонованого використання конфіденційної інформації залишеної
          Користувачем.
        </p>
        <p>
          Вся особиста інформація, отримана P-Trans в результаті здійснення
          замовлень через Сайт зберігається згідно з правилами та положеннями
          про конфіденційність, які є невід’ємною частиною Договору.
        </p>
        <p>
          P-Trans залишає за собою право використовувати та розкривати
          інформацію про Користувача або про замовлення здійсненні Користувачем,
          на умовах дотримання Конфіденційності.
        </p>
        <p>
          З метою захисту ділових інтересів, P-Trans надає відповідь на
          письмовий запит «Про надання інформації про Постачальника», що
          надійшов на нашу адресу з обов’язковим попередженням та отриманням
          дозволу Сторони по відношенні до якої здійснюється запит, у тому числі
          і за запитами відповідних державних чи інших органів уповноважених
          законом на отримання такої інформації.
        </p>
        <p>
          P-Trans зберігає за собою право змінювати правила Конфіденційності в
          будь-який час, виключно в сторону покращення, з подальшим розширенням
          умов збереження конфіденційної інформації.
        </p>
        <p>&nbsp;</p>
        <p><strong>ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ</strong></p>
        <p>
          Користувач надає згоду та підтверджує, що дані вказані ним при
          приданні Квитка правдиві, і Користувач має право вказати їх при
          купівлі Квитка та залишити для подальшої обробки та збереження, у тому
          числі, але не обмежуючись, для:
        </p>
        <ul>
          <li>
            передачі їх третій стороні, яка тим чи іншим чином пов’язана з цим
            Договором, або з метою його повного виконання;
          </li>
          <li>
            додаткового інформування Користувача, про статус виконання рейсу,
            зміни в його умовах;
          </li>
          <li>подальшої обробки замовлень Користувача;</li>
          <li>
            отриманні сервісних повідомлень, у тому числі щодо акцій, бонусних
            програм і знижок, що проводяться P-Trans.
          </li>
        </ul>
        <p><strong>ВІДПОВІДАЛЬНІСТЬ СТОРІН</strong></p>
        <p>
          У разі невиконання або неналежного виконання однією із Сторін
          зобов’язань за Договором, винна Сторона зобов’язана відшкодувати
          протилежній Стороні заподіяні і доведені такими діями збитки.
        </p>
        <p>
          Користувач особисто несе відповідальність за дотримання умов і правил
          обраного Постачальника та Договору і правил використання Сайту.
        </p>
        <p>
          Користувач несе одноосібну відповідальність, у зв’язку з не здатністю
          надати достовірну інформацію, у тому числі і про особу від імені якої
          він діє в межах даного Договору, включно, але не обмежуючись, за
          неможливість використання персональної інформації на Сайті, без
          будь-яких виключень, у тому числі, але не обмежуючись і у випадку
          відсутності у Користувача достовірної персональної інформації.
        </p>
        <p>
          Даний Договір регулює виключно умови придбання обраного Користувачем
          Квитка, який в свою чергу є свідченням укладення договору Перевезення.
        </p>
        <p>&nbsp;</p>
        <p><strong>ЗВІЛЬНЕННЯ ВІД ВІДПОВІДАЛЬНОСТІ СТОРІН</strong></p>
        <p>
          Сторона звільняється від відповідальності за порушення умов Договору,
          якщо вона доведе, що це порушення сталося внаслідок дії або
          бездіяльності третьої сторони.
        </p>
        <p>
          Користувач розуміє і погоджуєтесь з тим, що не несе жодної
          відповідальності за будь-які прямі, непрямі, побічні та штрафні
          збитки; а також за незручності пов’язанні з виконанням перевезення (у
          тому числі, але не обмежуючись, затримки рейсів, скасування рейсів,
          пропущені стикування рейсів) або інші можливі проблеми, що мали місце
          у зв’язку з Вашим відвідуванням або використанням Сайту.
        </p>
        <p>
          P-Trans не несе відповідальність за помилки або неточності в умовах і
          цінах з продажу автобусних Квитків та інших товарів і послуг, якщо
          такі надані Постачальником.
        </p>
        <p>
          P-Trans не несе відповідальності за збитки та/або втрату квитка на той
          чи інший вид транспорту, за збитки отримані Користувачем в результаті
          запізнення автобусу до пункту де Користувач мав здійснити пересадку, у
          тому числі придбаним одним замовленням через Сайт.
        </p>
        <p>&nbsp;</p>
        <p><strong>ПРИКІНЦЕВІ ПОЛОЖЕННЯ</strong></p>
        <p>Номер Договору відповідає номеру придбаного Користувачем Квитка.</p>
        <p>
          Датою укладення Договору, є дата здійснення платежу, в рахунок
          обраного Квитка.
        </p>
        <p>
          У всьому іншому, що не передбачене Договором, Сторони керуються чинним
          законодавством України.
        </p>
        <p>
          Договір Правила та Умови є цілісним документом призначеним для
          користувача і мають переважаючу юридичну силу, в порівнянні з раніше
          укладеними письмовими або усними угодами між сторонами. Роздрукована
          версія даних Правил і Умов або будь-якого іншого повідомлення,
          створеного спочатку в електронній формі, може розглядатися в судових
          процесах, пов’язаних з даним Договором. Правилами і Умовами, з тієї ж
          вагомістю і на тих же умовах, як інші бізнес-документи, спочатку
          створені не в електронній, а у фізичній формі.
        </p>
        <p>&nbsp;</p>
        <p><strong>ЛЕГІТИМНІСТЬ ЕЛЕКТРОННИХ ПОВІДОМЛЕНЬ</strong></p>
        <p>
          Основний канал комунікації між P-Trans і Користувачем – повідомлення
          електронними листами або публікації на сайті. Користувач погоджуєтеся
          з тим, що всі повідомлення, новини та інші комунікації в електронній
          формі Відповідають тим же юридичним вимогам, що і у паперовому
          вигляді.
        </p>
        <p>&nbsp;</p>
        <p><strong>ПРАВИЛА ВИКОРИСТАННЯ САЙТУ p-trans.pl.ua</strong></p>
        <p>
          Дані правила є невід’ємною частиною Договору публічної оферти і
          поширюються на всіх відвідувачів і користувачів Сайту p-trans.pl.ua
          (далі Сайт), по відношенню, як справжніх, так і майбутніх.
        </p>
        <p>
          Використовуючи Сайт або матеріали Сайту, Користувач висловлює свою
          безумовну згоду з даними Правилами.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong
            >Примітка, щодо користування цим сайтом дітьми. Даний сайт не
            призначений для дітей, тож просимо, щоб діти не надавали та/або
            залишали особисту інформацію на цьому сайті.</strong
          >
        </p>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
    mounted(){
        this.$vuetify.goTo(0,0)
    }
};
</script>

<style>
</style>